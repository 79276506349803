<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/activityInfo">活动管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="标题内容" prop="title">
        <el-input type="text" size="mini" v-model="queryModel.title"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="index" label="序号" :index="indexMethod" width="50"></el-table-column>
      <!-- <el-table-column prop="titleUrl" sort-by="title_url" label="标题图片" width="180">
        <template slot-scope="{row}">
          <a :href="row.titleUrl" target="_blank">
            <img
              :src="row.titleUrl+'?x-oss-process=image/resize,m_lfit,w_180'"
              :key="row.id"
              style="width:180px,fit-object:cover;"
            />
          </a>
        </template>
      </el-table-column>-->
      <el-table-column prop="title" sort-by="title_" label="标题" width="180"></el-table-column>
      <!-- <el-table-column prop="contentUrl" sort-by="content_url" label="内容图片" width="180">
        <template slot-scope="{row}">
          <a :href="row.contentUrl" target="_blank">
            <img
              :src="row.contentUrl+'?x-oss-process=image/resize,m_lfit,w_40'"
              :key="row.id"
              style="width:180px,fit-object:cover;"
            />
          </a>
        </template>
      </el-table-column> -->
      <el-table-column prop="id" sort-by="id_" label="活动编号" width="200"></el-table-column>
      <el-table-column prop="buttonName" sort-by="button_name" label="按钮名称" width="100"></el-table-column>
      <el-table-column prop="linkType" sort-by="link_type" label="按钮类型" width="100">
        <template slot-scope="{row}">
          <span v-if="row.linkType == '0'">跳转</span>
          <span v-else-if="row.linkType == '1'">领取</span>
        </template>
      </el-table-column>
      <el-table-column prop="linkUrl" sort-by="link_url" label="跳转地址" width="180"></el-table-column>
      <!-- <el-table-column prop="classify" sort-by="classify_" label="分类" width="180"></el-table-column>
      <el-table-column prop="enabled" label="审核状态" width="200">
        <template slot-scope="{row}">
          <el-switch
            v-model="row.enabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="updateSwitch(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="startTime" sort-by="start_time" label="开始时间" width="180"></el-table-column>
      <el-table-column prop="endTime" sort-by="end_time" label="结束时间" width="180"></el-table-column>-->
      <el-table-column prop="point" sort-by="point_" label="积分" width="180"></el-table-column>
      <el-table-column prop="sortNo" sort-by="sort_no" label="排序号" width="180"></el-table-column>
      <!-- <el-table-column prop="createTime" sort-by="create_time" label="创建时间" width="180"></el-table-column> -->
      <el-table-column label="操作" width="180">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <activityInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></activityInfo-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import ActivityInfoDetail from "./activityInfo-detail";
import activityInfoApi from "@/api/base/activityInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        title: "",
        titleUrl: "",
        contentUrl: "",
        linkType: "",
        linkUrl: "",
        buttonName: "",
        classify: "",
        enabled: "",
        sortNo: "",
        startTime: "",
        endTime: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        delFlag: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      aUrl: "",
    };
  },
  created() {
    this.aUrl =
      process.env.VUE_APP_BACKEND_URL +
      "/mobile/activityApi/activityDetail?id=";
  },
  methods: {
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("title", self.queryModel.title);
      formData.append("titleUrl", self.queryModel.titleUrl);
      formData.append("contentUrl", self.queryModel.contentUrl);
      formData.append("linkType", self.queryModel.linkType);
      formData.append("linkUrl", self.queryModel.linkUrl);
      formData.append("buttonName", self.queryModel.buttonName);
      formData.append("classify", self.queryModel.classify);
      formData.append("enabled", self.queryModel.enabled);
      formData.append("sortNo", self.queryModel.sortNo);
      formData.append("startTime", self.queryModel.startTime);
      formData.append("endTime", self.queryModel.endTime);
      formData.append("createBy", self.queryModel.createBy);
      formData.append("createTime", self.queryModel.createTime);
      formData.append("updateBy", self.queryModel.updateBy);
      formData.append("updateTime", self.queryModel.updateTime);
      formData.append("delFlag", self.queryModel.delFlag);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      activityInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          activityInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        activityInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    updateSwitch(row) {
      activityInfoApi.update(row);
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "activityInfo-detail": ActivityInfoDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>