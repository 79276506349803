
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-form-item label="标题内容" prop="title">
          <el-input
            v-model="formModel.title"
            placeholder="请输入标题内容"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="发送单位" prop="schools">
          <el-select-tree
            :options="companyResult"
            v-model="formModel.schools"
            height="200"
          ></el-select-tree>
        </el-form-item> -->
        <!-- <el-form-item label="标题图片" prop="titleUrl">
          <el-upload
            class="avatar-uploader"
            name="photoFile"
            :action="uploadUrl"
            :show-file-list="false"
            :headers="headers"
            :data="uploadData"
            :on-success="handleTitleSuccess"
            :before-upload="beforeTitleUpload"
          >
            <img v-if="fileTitleUrl" :src="fileTitleUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item> -->
        <el-form-item label="内容图片" prop="contentUrl">
          <el-upload
            class="avatar-uploader"
            name="photoFile"
            :action="uploadUrl"
            multiple
            :show-file-list="true"
            :headers="headers"
            :data="uploadData"
            :on-success="handleContentSuccess"
            :before-upload="beforeContentUpload"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <!-- <img v-if="fileContentUrl" :src="fileContentUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
          </el-upload>
        </el-form-item>
        <el-form-item label="跳转类型" prop="linkType">
          <el-radio-group v-model="formModel.linkType">
            <el-radio label="0">跳转</el-radio>
            <el-radio label="1">领取</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跳转链接地址" prop="linkUrl">
          <el-input
            v-model="formModel.linkUrl"
            placeholder="请输入跳转链接地址"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="按钮名称" prop="buttonName">
          <el-input
            v-model="formModel.buttonName"
            placeholder="请输入按钮名称"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="分类" prop="classify">
          <el-select v-model="formModel.classify" placeholder="请选择" style="width:30%">
            <el-option label="全部" value="0"></el-option>
            <el-option label="老师" value="1"></el-option>
            <el-option label="学生" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批状态" prop="enabled">
          <el-radio-group v-model="formModel.enabled">
            <el-radio :label="true">已审核</el-radio>
            <el-radio :label="false">未审核</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="积分" prop="point">
          <el-input
            v-model="formModel.point"
            placeholder="请输入积分"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序号" prop="sortNo">
          <el-input
            v-model="formModel.sortNo"
            placeholder="请输入序号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import activityInfoApi from "@/api/base/activityInfo";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";

import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: ["businessKey", "title"],
  // components: {
  //   "el-select-tree": SelectTree
  // },
  data() {
    return {
      formModel: {},
      ruleValidate: {
        title: [
          { required: true, message: "标题内容不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      companyResult: [],
      fileTitleUrl: "",
      fileContentUrl: "",
      //上传地址
      uploadUrl: Constant.serverUrl + "/uploadPicture",
      uploadData: {
        subFolder: "activityInfo",
      },
      headers: {
        Authorization: getToken(),
      },
      typeData: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      fileList:[]
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    this.loadTree();
  },
  methods: {
    loadTree() {
      var formData = new FormData();
      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return activityInfoApi.add(self.formModel);
            } else {
              return activityInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    handleTitleSuccess(res, file) {
      var self = this;
      self.formModel.titleUrl = res.data;
      self.fileTitleUrl =
        res.data + "?x-oss-process=image/resize,m_lfit,h_300,w_300";
    },
    beforeTitleUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isBMP = file.type === "image/bmp";
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error("上传图片只能是 JPG/GIF/PNG/BMP 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传图片大小不能超过 2MB!");
      // }
      //return isJPG && isLt2M;
      return isJPG || isGIF || isPNG || isBMP;
    },
    handleContentSuccess(res, file) {
      var self = this;
      if(self.formModel.contentUrl == null){
        self.formModel.contentUrl = res.data;
      }else{
        self.formModel.contentUrl = self.formModel.contentUrl + "," + res.data;
      }
      // self.fileContentUrl =
      //   res.data + "?x-oss-process=image/resize,m_lfit,h_300,w_300";
    },
    beforeContentUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isBMP = file.type === "image/bmp";
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error("上传图片只能是 JPG/GIF/PNG/BMP 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传图片大小不能超过 2MB!");
      // }
      //return isJPG && isLt2M;
      return isJPG || isGIF || isPNG || isBMP;
    },
  },
  mounted: function () {
    var self = this;
    (function () {
      if (self.businessKey.length == 0) {
        return activityInfoApi.create();
      } else {
        return activityInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          let titleUrl = self.formModel.titleUrl;
          if (titleUrl != null) {
            self.fileTitleUrl =
              titleUrl + "?x-oss-process=image/resize,m_lfit,h_300,w_300";
          }

          let contentUrl = self.formModel.contentUrlMap;
          if (contentUrl != null) {
            // self.fileContentUrl =
            //   contentUrl + "?x-oss-process=image/resize,m_lfit,h_300,w_300";
            //self.fileContentUrl = contentUrl.split(",");
            
            self.fileList = contentUrl;
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>